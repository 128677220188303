p{
  color: #1E1F20 !important;
}

.App{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: whitesmoke !important;
}

/* :root {
  --swiper-theme-color: #a97142 !important;
} */